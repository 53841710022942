import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Family from "./icons/Family";
import Wallet from "./icons/Wallet";
import Education from "./icons/Education";
import Settings from "./icons/Settings";
import EditProfileIcn from "./icons/EditProfileIcn";
import Logout from "./icons/Logout";
import Timeline from "./icons/Timeline";
import { SESSSION_OUT } from "../../../../Utils/constants";
import { BASE_URL } from "../../../../Utils/url";
import Notification from "./icons/settings/Notifications";
import Protection from "./icons/Protection";
import Insights from "./icons/Insights";
import Piggy from "./icons/Piggy";
import NotificationsSenior from "./icons/settings/NotificationsSenior";
import SeniorDashboard from "./icons/settings/SeniorDashboard";
import Budgets from "./icons/Budgets";
import Shopping from "./icons/Shopping";
import Bills from "./icons/Bills";
import Caregiver from "./icons/Caregiver";
//import pkg from '../../../../../package.json'

const SeniorSidebar = (props) => {

  const { t } = useTranslation();
  const location = useLocation();
  const isBank = !props.showHeader || sessionStorage.getItem("__bank");

  const logout = () => {
    SESSSION_OUT(props);
  };

  const toggleSidebar = () => {
    document.querySelector('.parent-left-sidebar').classList.toggle('active');
  }

  return (
    <div className="parent-left-sidebar">
      <ul>
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/dashboard" ? 'active' : ''}`}>
          <Link to="/parent/dashboard">
            <span className="menu-icon">
              <span className="svgIcon">
                <Family {...props} />
              </span>
            </span>
            <span>{t('my_family')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/senior/dashboard" ? 'active' : ''}`}>
          <Link to="/parent/senior/dashboard">
            <span className="menu-icon">
              <span className="svgIcon">
                <SeniorDashboard {...props}/>
              </span>
            </span>
            <span>{t('Dashboard')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/senior/accounts" ? 'active' : ''}`}>
          <Link to="/parent/senior/accounts">
            <span className="menu-icon">
              <span className="svgIcon">
                <Piggy {...props}/>
              </span>
            </span>
            <span>{t('Accounts')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/senior/budget" ? 'active' : ''}`}>
          <Link to="/parent/senior/budget">
            <span className="menu-icon">
              <span className="svgIcon">
                <Budgets {...props}/>
              </span>
            </span>
            <span>{t('Budget')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/senior/spendings" ? 'active' : ''}`}>
          <Link to="/parent/senior/spendings">
            <span className="menu-icon">
              <span className="svgIcon">
                <Shopping {...props}/>
              </span>
            </span>
            <span>{t('Spending')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/senior/recurringBills" ? 'active' : ''}`}>
          <Link to="/parent/senior/recurringBills">
            <span className="menu-icon">
              <span className="svgIcon">
                <Bills {...props}/>
              </span>
            </span>
            <span>{t('Bills')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/senior/protection" ? 'active' : ''}`}>
          <Link to="/parent/senior/protection">
            <span className="menu-icon">
              <span className="svgIcon">
                <Protection {...props}/>
              </span>
            </span>
            <span>{t('Protection')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/senior/financialCaregivers" ? 'active' : ''}`}>
          <Link to="/parent/senior/financialCaregivers">
            <span className="menu-icon">
              <span className="svgIcon">
                <Caregiver {...props} />
              </span>
            </span>
            <span>{t('Caregivers')}</span>
          </Link>
        </li>

        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/senior/notifications" ? 'active' : ''}`}>
          <Link to="/parent/senior/notifications">
            <span className="menu-icon">
              <span className="svgIcon">
                <NotificationsSenior {...props} color={props.state.theme.values.tertiaryGrayColor}/>
              </span>
            </span>
            <span>{t('Notifications')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/senior/settings" ? 'active' : ''}`}>
          <Link to="/parent/senior/settings">
            <span className="menu-icon">
              <span className="svgIcon">
                <Settings {...props} />
              </span>
            </span>
            <span>{t('settings')}</span>
          </Link>
        </li>
        {/* <li className={`${location.pathname==="/help"?'active':''}`}>
          <Link to="/parent/help">
            <span className="menu-icon">
              <span className="svgIcon">
                <Help/>
              </span>
            </span>
            <span>{t('help')}</span>
          </Link>
        </li> */}

        {!isBank &&
          <li onClick={toggleSidebar} className={`${location.pathname === "/parent/logout" ? 'active' : ''}`}>
            <a href="#logout" onClick={logout}>
              <span className="menu-icon">
                <span className="svgIcon">
                  <Logout {...props} />
                </span>
              </span>
              <span>{t('sign_out')}</span>
            </a>
          </li>
        }
        {/* <li style={{marginLeft: `40px`}}>
            v{pkg.version}
          </li> */}
      </ul>
    </div>
  );
};

export default SeniorSidebar;
