import React from "react";

const NotificationsSenior = (props) => {
  return (
    <svg
      className="menu-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      id="piggyIcon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.0953 17.0156C22.1237 16.0312 20.2316 14.5781 20.2316 9.75C20.2316 6.14062 17.4703 3.23438 13.6862 2.48438V1.5C13.6862 0.703125 12.9703 0 12.101 0C11.1805 0 10.4646 0.703125 10.4646 1.5V2.48438C6.6805 3.23438 3.91914 6.14062 3.91914 9.75C3.91914 14.5781 2.02709 16.0312 1.0555 17.0156C0.748682 17.2969 0.595273 17.6719 0.646409 18C0.646409 18.7969 1.26005 19.5 2.28277 19.5H21.868C22.8907 19.5 23.5044 18.7969 23.5555 18C23.5555 17.6719 23.4021 17.2969 23.0953 17.0156ZM4.07254 17.25C5.14641 15.9844 6.32254 13.7812 6.37368 9.79688C6.37368 9.79688 6.37368 9.79688 6.37368 9.75C6.37368 6.89062 8.9305 4.5 12.101 4.5C15.2203 4.5 17.8282 6.89062 17.8282 9.75C17.8282 9.79688 17.7771 9.79688 17.7771 9.79688C17.8282 13.7812 19.0044 15.9844 20.0782 17.25H4.07254ZM12.101 24C13.8907 24 15.3225 22.6875 15.3225 21H8.82823C8.82823 22.6875 10.26 24 12.101 24Z"
        fill={props.color}
      />
    </svg>
  );
};

export default NotificationsSenior;
