import React from "react";

const Budgets = (props) => {
  const iconColor = props.state?.theme?.values?.tertiaryGrayColor;

  return (
    <svg
      id="piggyIcon"
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0285 3.50561C12.4612 3.89739 12.708 4.4539 12.708 5.03762V12.3621H20.9974C22.1388 12.3621 23.064 13.2874 23.064 14.4288C23.064 20.7777 17.9285 25.9334 11.5828 25.9334C5.23707 25.9334 0.101562 20.7779 0.101562 14.4289C0.101562 8.46644 4.62872 3.55919 10.4367 2.98112C11.0175 2.9233 11.5957 3.11383 12.0285 3.50561ZM10.6414 5.03762C9.92587 5.10883 9.23395 5.26035 8.57469 5.48304C4.8503 6.74113 2.16823 10.271 2.16823 14.4289C2.16823 19.6413 6.38328 23.8668 11.5828 23.8668C16.0742 23.8668 19.831 20.7139 20.771 16.4954C20.9192 15.8304 20.9974 15.1388 20.9974 14.4289L11.6747 14.4288C11.104 14.4288 10.6414 13.9661 10.6414 13.3954V5.03762Z"
        fill={iconColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.4142 10.4141C24.0391 10.7892 23.5304 10.9999 23 10.9999H16C14.8954 10.9999 14 10.1045 14 8.99991V1.99991C14 0.895339 14.8954 -9.15527e-05 16 -9.15527e-05C20.9706 -9.15527e-05 25 4.02935 25 8.99991C25 9.53034 24.7893 10.039 24.4142 10.4141ZM22.7101 6.99991C22.0382 4.74213 20.2578 2.96174 18 2.28979C17.3663 2.10119 16.695 1.99991 16 1.99991V8.99991H23C23 8.30494 22.8987 7.63362 22.7101 6.99991Z"
        fill={iconColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.23047 20.4019L11.3001 12.3322L12.7615 13.7936L4.69182 21.8632L3.23047 20.4019Z"
        fill={iconColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.472 21.8632L10.4023 13.7936L11.8637 12.3322L19.9333 20.4019L18.472 21.8632Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default Budgets;
