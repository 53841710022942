import React from "react";

const Bills = (props) => {
  const iconColor = props.state?.theme?.values?.tertiaryGrayColor;

  return (
    <svg
      id="piggyIcon"
      width="23"
      height="26"
      viewBox="0 0 23 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 2.16661C0 0.969989 0.97005 -6.10352e-05 2.16667 -6.10352e-05H20.5833C21.78 -6.10352e-05 22.75 0.969989 22.75 2.16661V23.8333C22.75 25.0299 21.78 25.9999 20.5833 25.9999H2.16667C0.97005 25.9999 0 25.0299 0 23.8333V2.16661ZM2.16667 2.16661V23.8333H20.5833V2.16661H2.16667Z"
        fill={iconColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.33203 5.4166C4.33203 4.81829 4.81706 4.33327 5.41536 4.33327L17.332 4.33327C17.9303 4.33327 18.4154 4.81829 18.4154 5.4166C18.4154 6.01491 17.9303 6.49993 17.332 6.49993L5.41536 6.49993C4.81706 6.49993 4.33203 6.01491 4.33203 5.4166Z"
        fill={iconColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.33203 11.9166C4.33203 11.3183 4.81706 10.8333 5.41536 10.8333L11.9154 10.8333C12.5137 10.8333 12.9987 11.3183 12.9987 11.9166C12.9987 12.5149 12.5137 12.9999 11.9154 12.9999L5.41536 12.9999C4.81706 12.9999 4.33203 12.5149 4.33203 11.9166Z"
        fill={iconColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5469 11.9166C14.5469 11.3183 15.0319 10.8333 15.6302 10.8333L17.3326 10.8333C17.9309 10.8333 18.4159 11.3183 18.4159 11.9166C18.4159 12.5149 17.9309 12.9999 17.3326 12.9999L15.6302 12.9999C15.0319 12.9999 14.5469 12.5149 14.5469 11.9166Z"
        fill={iconColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.33203 16.2499C4.33203 15.6516 4.81706 15.1666 5.41536 15.1666H11.9154C12.5137 15.1666 12.9987 15.6516 12.9987 16.2499C12.9987 16.8483 12.5137 17.3333 11.9154 17.3333H5.41536C4.81706 17.3333 4.33203 16.8483 4.33203 16.2499Z"
        fill={iconColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5469 16.2499C14.5469 15.6516 15.0319 15.1666 15.6302 15.1666L17.3326 15.1666C17.9309 15.1666 18.4159 15.6516 18.4159 16.2499C18.4159 16.8483 17.9309 17.3333 17.3326 17.3333L15.6302 17.3333C15.0319 17.3333 14.5469 16.8483 14.5469 16.2499Z"
        fill={iconColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.33203 20.5833C4.33203 19.985 4.81706 19.4999 5.41536 19.4999L11.9154 19.4999C12.5137 19.4999 12.9987 19.985 12.9987 20.5833C12.9987 21.1816 12.5137 21.6666 11.9154 21.6666H5.41536C4.81706 21.6666 4.33203 21.1816 4.33203 20.5833Z"
        fill={iconColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5469 20.5833C14.5469 19.985 15.0319 19.4999 15.6302 19.4999L17.3326 19.4999C17.9309 19.4999 18.4159 19.985 18.4159 20.5833C18.4159 21.1816 17.9309 21.6666 17.3326 21.6666L15.6302 21.6666C15.0319 21.6666 14.5469 21.1816 14.5469 20.5833Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default Bills;
