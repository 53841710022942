import * as utils from "../../Utils/index";

export const THEME_CALL_FONTSIZE=()=>{
    let increment = sessionStorage.getItem('zoomPercent');
    increment = increment ? JSON.parse(increment) : 0;
    const sizes = utils.fontSizesEnum();

    sizes.forEach((element) => {
        if(element != 56){
            const increaseValue = element + (element * increment / 100);
            document.documentElement.style.setProperty(`--font-${element}`, increaseValue+'px');
        }else{
            document.documentElement.style.setProperty(`--font-${element}`, '58px');
        }
      
    });
}

export const THEME_CALL_CONTRAST = (_THEME)=>{
    const contrast = sessionStorage.getItem('contrast');

    if(contrast){
        _THEME.primaryColor = utils.formatContrastColorsDark(_THEME.primaryColor);
        _THEME.tertiaryColor = utils.formatContrastColorsDark(_THEME.tertiaryColor);
        _THEME.tertiaryGrayColor = utils.formatContrastColorsDark(_THEME.tertiaryGrayColor);
        _THEME.tertiaryWardColor = utils.formatContrastColorsDark(_THEME.tertiaryWardColor);
        _THEME.primaryGrayColor = utils.formatContrastColorsDark(_THEME.primaryGrayColor);
        _THEME.primaryWardColor = utils.formatContrastColorsDark(_THEME.primaryWardColor);
        _THEME.secondaryColor = utils.formatContrastColorsDark(_THEME.secondaryColor);
        _THEME.secondaryWardColor = utils.formatContrastColorsDark(_THEME.secondaryWardColor);
        _THEME.errorColor = utils.formatContrastColorsDark(_THEME.errorColor);
        _THEME.secondaryGrayColor = utils.formatContrastColorsDark(_THEME.secondaryGrayColor);
        _THEME.gray5 = utils.formatContrastColorsDark(_THEME.gray5);
    }

    return _THEME;
}

export const THEME_CALL = (_THEME) => {
   
    document.documentElement.style.setProperty('$primary-color', _THEME.primaryColor);
    document.documentElement.style.setProperty('--primaryColor', _THEME.primaryColor);
    document.documentElement.style.setProperty('--primaryWardColor', _THEME.primaryColor);
    document.documentElement.style.setProperty('--secondaryColor', _THEME.secondaryColor);
    document.documentElement.style.setProperty('--tertiaryGrayColor', _THEME.tertiaryGrayColor);
    document.documentElement.style.setProperty('--primaryGrayColor', _THEME.primaryGrayColor);
    document.documentElement.style.setProperty('--tertiaryColor', _THEME.tertiaryColor);
    document.documentElement.style.setProperty('--errorColor', _THEME.errorColor);
    document.documentElement.style.setProperty('--secondaryGrayColor', _THEME.secondaryGrayColor);
    document.documentElement.style.setProperty('--gray5', _THEME.gray5);

    if(_THEME?.variables?.borderRadius){
        document.documentElement.style.setProperty('--borderRadiusS', _THEME.variables.borderRadius.s);
        document.documentElement.style.setProperty('--borderRadiusM', _THEME.variables.borderRadius.m);
        document.documentElement.style.setProperty('--borderRadiusL', _THEME.variables.borderRadius.l);
        document.documentElement.style.setProperty('--borderRadiusXL', _THEME.variables.borderRadius.xl);
    }else{
        document.documentElement.style.setProperty('--borderRadiusS', '8px');
        document.documentElement.style.setProperty('--borderRadiusM', '12px');
        document.documentElement.style.setProperty('--borderRadiusL', '16px');
        document.documentElement.style.setProperty('--borderRadiusXL', '32px');
    }
    

    if(!_THEME.showHeader){
        document.querySelectorAll(`body`).forEach(e => e.classList.add(`page-no-header`));
    }else{
        document.querySelectorAll(`body`).forEach(e => e.classList.remove(`page-no-header`));
    }

    document.querySelectorAll('.logo').forEach(e=>{
        e.setAttribute('src',_THEME.logo);
    })
}

export const THEME_CALL_WARD = (_THEME) => {
   
    document.documentElement.style.setProperty('$primary-color', _THEME.primaryWardColor);
    document.documentElement.style.setProperty('--primaryColor', _THEME.primaryWardColor);
    document.documentElement.style.setProperty('--primaryWardColor', _THEME.primaryWardColor);
    document.documentElement.style.setProperty('--secondaryColor', _THEME.secondaryWardColor);

    if(!_THEME.showHeader){
        document.querySelectorAll(`body`).forEach(e => e.classList.add(`page-no-header`));
    }else{
        document.querySelectorAll(`body`).forEach(e => e.classList.remove(`page-no-header`));
    }

    document.querySelectorAll('.logo').forEach(e=>{
        e.setAttribute('src',_THEME.logo);
    })
}