import {
    THEME,
    THEME_SET_SUCCESS,
    THEME_SET_TRIGGER
} from "../actionType";

import * as constants from './../../Utils/constants';

import WHITELABELING from "../../Components/Themes";
import { THEME_CALL, THEME_CALL_CONTRAST, THEME_CALL_FONTSIZE } from "../../Components/Themes/Theme";
import { getTenantName } from "../../Utils";

const INITIAL_STATE = {
    initalThemeValues: { ...WHITELABELING },
    values: { ...WHITELABELING }
};

const theme = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case THEME:
            //console.log('theme called');
            return {
                ...state,
            };

        case THEME_SET_TRIGGER:
            //console.log(action.payload);
            return action.payload;
        case THEME_SET_SUCCESS:
            //console.log(action.type);
            try {
                
                const res = {
                    appIdToTenantMap: action.payload.appIdToTenantMap,
                    tenants: []
                };

                if (action.payload.tenants) {

                    action.payload.tenants.forEach(e => {
                        const tenants = res.tenants;
                        res.tenants = { ...tenants, ...e };
                    })

                    action.payload.appIdToTenantMap['localhost'] = "TreasuryPrime";
                    const tenantName = getTenantName(action.payload.appIdToTenantMap); //"DemoBank";//getTenantName();
                    document.querySelectorAll(`body`).forEach(e => e.setAttribute("id", "tenant-" + tenantName.toLowerCase()));
                    
                    let resEntity = res.tenants[tenantName];
                    resEntity.baseDomain = resEntity.baseUrl;
                    resEntity.baseUrl = `https://${resEntity.baseUrl}`;
                    resEntity.gray5 = `#D3D7D7`;
                    resEntity.shortName = resEntity.shortName.replace(/\s/g, '');
                    resEntity.tenantFullName = tenantName;
                    // resEntity.primaryColor = "#395350"
                    // resEntity.showHeader = false;
                    let initalThemeValues = JSON.parse(JSON.stringify(resEntity));
                    
                    resEntity = THEME_CALL_CONTRAST(resEntity);

                    THEME_CALL_FONTSIZE();
                    THEME_CALL(resEntity);

                    const finalState = {
                        initalThemeValues: { ...state.initalThemeValues, ...initalThemeValues },
                        values: { ...state.values, ...resEntity }
                    }
                    //console.log(finalState);
                    return finalState;
                } else {
                    return state;
                }
            } catch (err) {
                console.log(err);
            }
            return state;
        default:
            return state;
    }
};
export default theme;